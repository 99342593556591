<template>
  <div class="brand" style="height: 100%">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="菜单" name="1">
        <el-table
            :data="tableData"
            size="medium"
            height="calc(100vh - 200px)"
            row-key="id"
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column
              prop="name"
              min-width="300"
              label="菜单名称">
          </el-table-column>
          <el-table-column
              prop="text"
              width="70"
              label="状态">
            <template #default="scope">
              <span v-if="scope.row.display==0">显示</span>
              <span v-else>隐藏</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="sort"
              width="70"
              label="排序">
          </el-table-column>
          <el-table-column
              prop="text"
              width="150"
              label="操作">
            <template #header>
              <el-button  @click="add()" size="mini" type="primary" style="margin:10px 0;">新增</el-button>
            </template>
            <template #default="scope">
              <div style="margin: 5px 0">
                <el-button-group>
                  <el-button size="mini" type="primary" icon="el-icon-circle-plus-outline" @click="add(scope.row)"></el-button>
                 <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                  <el-button size="mini"  type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
                </el-button-group>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="API" name="2">
        <el-table
            :data="tableDataApi"
            size="medium"
            height="calc(100vh - 150px)"
            row-key="id"
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column
              prop="name"
              min-width="300"
              label="API名称">
          </el-table-column>
          <el-table-column
              prop="text"
              width="70"
              label="状态">
            <template #default="scope">
              <span v-if="scope.row.display==0">显示</span>
              <span v-else>隐藏</span>
            </template>
          </el-table-column>
          <!--      <el-table-column-->
          <!--          prop="description"-->
          <!--          label="技能说明">-->
          <!--      </el-table-column>-->
          <el-table-column
              prop="text"
              width="120"
              label="操作">
            <template #header>
              <el-button  @click="add()" size="mini" type="primary" style="margin:10px 0;">新增</el-button>
            </template>
            <template #default="scope">
              <div style="margin: 5px 0">
                <el-button-group>
                  <!--              <el-button size="mini" type="primary" icon="el-icon-plus" @click="add(scope.row,'nowadd')"></el-button>-->
                  <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                  <el-button size="mini"  type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
                </el-button-group>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="target">
          <el-select  style="width:100%" v-model="ruleForm.target" >
            <el-option
                v-for="item in targetArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级名称" prop="pid">
          <el-cascader
              placeholder=""
              ref="cascaderHandle"
              style="width: 100%"
              :options="areaArr"
              v-model="ruleForm.pid"
              :props="{
                        label: 'name',
                        value: 'id',
                        children: 'children',
                        checkStrictly: true
                      }"
              :filterable="true">
          </el-cascader>
        </el-form-item>
        <el-form-item v-if="ruleForm.target==1" label="组件地址" prop="component">
          <el-input v-model="ruleForm.component"></el-input>
        </el-form-item>
        <el-form-item v-else label="api url" prop="url">
          <el-input v-model="ruleForm.url"></el-input>
        </el-form-item>
        <el-form-item label="图标样式" prop="icon_class">
          <el-input v-model="ruleForm.icon_class"></el-input>
        </el-form-item>
        <el-form-item label="唯一性标识" prop="code">
          <el-input v-model="ruleForm.code"></el-input>
        </el-form-item>
        <el-form-item label="排序" >
          <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="ruleForm.sort"></el-input-number>
        </el-form-item>
        <el-form-item label="状态" prop="display">
          <div style="display: flex;justify-content: space-between;align-items:center;height: 40px">
            <el-switch
                style="display: block"
                v-model="ruleForm.display"
                active-text="显示"
                inactive-text="隐藏"
                :active-value="0"
                :inactive-value="1">
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import {getArea, getCharge} from "@/api/common";
import * as axios from "@/api/menuApi"//本页面接口
export default {
  name: 'brand',
  data(){
    return{
      activeName:"1",
      login:false,
      drawer:false,
      tableData:[],
      tableDataApi:[],
      fatherArr:[],
      areaArr:[],
      targetArr:[
        {
          id:0,
          name:'api',
        },
        {
          id:1,
          name:'菜单',
        },
      ],

      ruleForm:{
        name:'',
        pid:'',
        target:1,
        sort:50,
        url:'',
        component:'',
        display:0,
        icon_class:'',
        code:'',
      },
      rules:{
        name: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        pid: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        description: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        component: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        url: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
      }
    }
  },
  created() {
    this.onTable()
  },
  methods:{
    onTable(){
      axios.getmenuData({type:1}).then((res)=>{
        console.log(res)
        this.tableData=res.data
        this.areaArr=JSON.parse(JSON.stringify(res.data))
        this.areaArr.unshift({
          name:'顶层分类',
          id:0
        })
        console.log('this.tableData',this.tableData)
      }).catch((err)=>{

      })
    },
    onTableApi(){
      axios.getmenuData({type:0}).then((res)=>{
        console.log(res)
        this.tableDataApi=res.data
        this.areaArr=JSON.parse(JSON.stringify(res.data))
        this.areaArr.unshift({
          name:'顶层分类',
          id:0
        })
        console.log('this.tableData',this.tableData)
      }).catch((err)=>{

      })
    },
    handleClick(){
      if(this.activeName==1){
        this.onTable()
      }else {
        this.onTableApi()
      }

    },
    add(row,val){
      this.ruleForm= {
        name:'',
        pid:'',
        target:1,
        sort:50,
        url:'',
        component:'',
        display:0,
        icon_class:'',
        code:'',
      }
      if(row){
        this.ruleForm.pid=row.id
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.pid=row.pid
      this.ruleForm.target=row.target
      this.ruleForm.sort=row.sort
      this.ruleForm.url=row.url
      this.ruleForm.component=row.component
      this.ruleForm.display=row.display
      this.ruleForm.icon_class=row.icon_class
      this.ruleForm.code=row.code
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      // this.ruleForm.father_info=JSON.stringify(this.ruleForm.father_info)

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login=true
          if(val==1){
            axios.postaddmenu(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditmenu(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelmenu({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >

</style>